<template>
  <a-modal
    title="生成电费账单"
    :width="780"
    :visible="visible"
    :confirmLoading="loading"
    @ok="() => { $emit('ok', queryParam) }"
    @cancel="() => { $emit('cancel') }"
  >
    <a-spin :spinning="loading">
      <a-form :form="form" v-bind="formLayout">
        <a-row :gutter="[16,16]">
          <a-col :span="8">
            <a-form-item label="电表记录批次" v-if="false">
              <a-select
                option-label-prop="tt"
                :show-arrow="false"
                :filter-option="false"
                :not-found-content="null"
                show-search
                allow-clear
                v-decorator="[
                  'batch2',
                  {
                    rules: [
                      { required: true, message: '' },
                    ],
                  },
                ]"
                placeholder="请输入批次"
                :default-active-first-option="false"
                @search="this.corporationSearch"
                @change="handlecorporationChange"
              >
                <a-select-option v-for="d in dataSource" :key="d.batch" :tt="d.batch">
                  {{ d.batch }}
                </a-select-option>
              </a-select>
            </a-form-item>
            <a-form-item label="批次">
              <a-input v-decorator="['batch', {rules: [{required: true, message: '必填项，请填写信息'}, {max:32, message: '输入超出长度限制'}]}]" />
            </a-form-item>
          </a-col>
          <a-col :md="16" :sm="24">
            <a-form-item label="日期">
              <a-range-picker @change="time" format="YYYY-MM-DD" style="width: 100%">
                <template slot="dateRender" slot-scope="current">
                  <div class="ant-calendar-date" :style="getCurrentStyle(current)" >
                    {{ current.date() }}
                  </div>
                </template>
              </a-range-picker>
            </a-form-item>
          </a-col>
        </a-row>
      </a-form>
    </a-spin>
  </a-modal>
</template>

<script>
// import pick from 'lodash.pick'
import debounce from 'lodash/debounce'
import { electricity_meter_record_list } from '@/api/electricity-meter'

// 表单字段
// const fields = ['id', 'number', 'corporation_id', 'total_payment', 'actual_payment']
export default {
  components: {},
  props: {
    visible: {
      type: Boolean,
      required: true
    },
    loading: {
      type: Boolean,
      default: () => false
    },
    model: {
      type: Object,
      default: () => null
    }
  },
  data () {
    this.formLayout = {
      labelCol: {
        xs: { span: 24 },
        sm: { span: 7 }
      },
      wrapperCol: {
        xs: { span: 24 },
        sm: { span: 13 }
      }
    }
    this.corporationSearch = debounce(this.handlecorporationSearch, 800)
    return {
      tip: '',
      dataSource: [],
      formItemLayout: {
        labelCol: {
          xs: { span: 24 },
          sm: { span: 4 }
        },
        wrapperCol: {
          xs: { span: 24 },
          sm: { span: 20 }
        }
      },
      color: '#3f3f3f',
      form: this.$form.createForm(this),
      dictionaries: this.$Dictionaries,
      queryParam: {}
    }
  },
  created () {
    console.log('custom modal created', this.form)
    // 防止表单未注册
    // fields.forEach(v => this.form.getFieldDecorator(v))
    // // 当 model 发生改变时，为表单设置值
    // this.$watch('model', () => {
    //   this.model && this.form.setFieldsValue(pick(this.model, fields))
    // })
    // this.handlecorporationSearch(undefined)
  },
  methods: {
    onChange (value) {
      console.log(value)
    },
    // 公司
    handlecorporationSearch (value) {
      electricity_meter_record_list({ batch: value }).then((res) => {
        const result = res.data.entries || []
        this.dataSource = !value ? (result.length > 5 ? result.slice(0, 5) : result) : result
      })
    },
    handlecorporationChange (value) {
      console.log(value)
      if (value === undefined) {
        this.handlecorporationSearch(value)
      }
    },
    onSelect (value) {
      console.log(value, '我是选择的值', this.corList)
      // eslint-disable-next-line eqeqeq
      const corporation_id = this.corList.filter((item) => (item.name === value) && item)[0].id
      this.form.setFieldsValue({
        corporation_id: corporation_id
      })
      console.log('onSelect', value, this.corList.filter((item) => (item.name === value) && item))
    },
    displayRender ({ labels }) {
      return labels[labels.length - 1]
    },
    time (dates, dateStrings) {
      console.log(dateStrings, '时间')
      this.queryParam.start_date = dateStrings[0]
      this.queryParam.end_date = dateStrings[1]
    },
    getCurrentStyle (current, today) {
      const style = {}
      if (current.date() === 1) {
        style.border = '1px solid #1890ff'
        style.borderRadius = '50%'
      }
      return style
    }
  }
}
</script>
