import request from '@/utils/request'

const electricityMeterApi = {
  electricity_meter_list: '/electricity_meter/',
  electricity_meter_create: '/electricity_meter/',
  electricity_meter_update: '/electricity_meter/',
  electricity_meter_delete: '/electricity_meter/',
  electricity_meter_record_list: '/electricity_meter/record/',
  electricity_meter_record_create: '/electricity_meter/record/',
  electricity_meter_record_update: '/electricity_meter/record/',
  electricity_meter_record_delete: '/electricity_meter/record/'
}

/**
 * 列表
 */
export function electricity_meter_list (parameter) {
  return request({
    url: electricityMeterApi.electricity_meter_list,
    method: 'get',
    headers: {
      'Content-Type': 'application/json;charset=UTF-8'
    },
    params: parameter
  })
}
/**
 * 添加
 */
export function electricity_meter_create (parameter) {
  return request({
    url: electricityMeterApi.electricity_meter_create,
    method: 'post',
    headers: {
      'Content-Type': 'application/json;charset=UTF-8'
    },
    data: parameter
  })
}
/**
 * 修改
 */
export function electricity_meter_update (parameter, electricity_meter_id) {
  return request({
    url: electricityMeterApi.electricity_meter_update + electricity_meter_id + '/',
    method: 'patch',
    headers: {
      'Content-Type': 'application/json;charset=UTF-8'
    },
    data: parameter
  })
}
/**
 * 删除
 */
export function electricity_meter_delete (electricity_meter_id) {
  return request({
    url: electricityMeterApi.electricity_meter_delete + electricity_meter_id + '/',
    method: 'delete',
    headers: {
      'Content-Type': 'application/json;charset=UTF-8'
    }
  })
}

/**
 * 列表
 */
export function electricity_meter_record_list (parameter) {
  return request({
    url: electricityMeterApi.electricity_meter_record_list,
    method: 'get',
    headers: {
      'Content-Type': 'application/json;charset=UTF-8'
    },
    params: parameter
  })
}
/**
 * 添加
 */
export function electricity_meter_record_create (parameter) {
  return request({
    url: electricityMeterApi.electricity_meter_record_create,
    method: 'post',
    headers: {
      'Content-Type': 'application/json;charset=UTF-8'
    },
    data: parameter
  })
}
/**
 * 修改
 */
export function electricity_meter_record_update (parameter, electricity_meter_record_id) {
  return request({
    url: electricityMeterApi.electricity_meter_record_update + electricity_meter_record_id + '/',
    method: 'patch',
    headers: {
      'Content-Type': 'application/json;charset=UTF-8'
    },
    data: parameter
  })
}
/**
 * 删除
 */
export function electricity_meter_record_delete (electricity_meter_record_id) {
  return request({
    url: electricityMeterApi.electricity_meter_record_delete + electricity_meter_record_id + '/',
    method: 'delete',
    headers: {
      'Content-Type': 'application/json;charset=UTF-8'
    }
  })
}

/**
 * 列表
 */
export function single_electricity_meter_list (id) {
  return request({
    url: electricityMeterApi.electricity_meter_list + id + '/detail/',
    method: 'get',
    headers: {
      'Content-Type': 'application/json;charset=UTF-8'
    }
  })
}

/**
 * 创建
 */
export function single_electricity_meter_create (id, parameter) {
  return request({
    url: electricityMeterApi.electricity_meter_list + id + '/detail/',
    method: 'post',
    headers: {
      'Content-Type': 'application/json;charset=UTF-8'
    },
    data: parameter
  })
}
