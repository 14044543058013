<template>
  <div>
    <div class="table-page-search-wrapper">
      <a-form layout="inline">
        <a-row :gutter="48">
          <a-col :md="6" :sm="24">
            <a-form-item label="编号">
              <a-input v-model="queryParam.number" placeholder="请输入电表编号" allow-clear/>
            </a-form-item>
          </a-col>
          <a-col :md="6" :sm="24">
            <a-form-item label="批次">
              <a-input v-model="queryParam.batch" placeholder="请输入批次" allow-clear/>
            </a-form-item>
          </a-col>
          <a-col :md="4" :sm="24">
            <a-button type="primary" @click="$refs.table.refresh(true)">查询</a-button>
          </a-col>
        </a-row>
      </a-form>
    </div>
    <div class="table-operator">
      <a-button type="primary" icon="plus" @click="handleAdd">新建</a-button>
      <a-button type="primary" @click="electricity_bill_c">生成电费账单</a-button>
    </div>

    <s-table
      ref="table"
      size="small"
      rowKey="id"
      :loading="loading"
      :columns="columns"
      :data="loadData"
    >
      <span slot="serial" slot-scope="text, record, index">
        {{ index + 1 }}
      </span>
      <div slot="money_render" slot-scope="text">
        {{ (text / 100).toFixed(2) | numberFormat }}
      </div>
      <div slot="money_render_4" slot-scope="text">
        {{ (text / 100).toFixed(4) | numberFormat }}
      </div>
      <span slot="action" slot-scope="text, record">
        <template>
          <a-popconfirm
            v-if="record.bill_id === -1"
            title="确定要删除么？"
            ok-text="确定"
            cancel-text="取消"
            @confirm="handleDelet(record)"
          >
            <a>删除</a>
          </a-popconfirm>
        </template>
      </span>
    </s-table>
    <create-form
      ref="createModal"
      :visible="visible"
      :loading="confirmLoading"
      :model="mdl"
      @cancel="handleAddCancel"
      @ok="handleOk"
    />
    <edit-form
      ref="editModal"
      :visible="editvisible"
      :loading="editconfirmLoading"
      :model="editmdl"
      @cancel="handleEditCancel"
      @ok="handleEditOk"
      :key="key"
    />
  </div>
</template>

<script>
import moment from 'moment'
import { STable } from '@/components'
import {
  electricity_meter_record_list,
  electricity_meter_record_create,
  electricity_meter_record_delete
} from '@/api/electricity-meter'
import { Dictionaries } from '@/common/AllConstant'
import CreateForm from '../modules/CreateForm'
import EditForm from '../modules/EditForm'
import { getForms, createProcessResult } from '@/api/design'
import { electricity_bill_create } from '@/api/electricity'

export default {
  name: 'TableList',
  components: {
    STable,
    CreateForm,
    EditForm
  },
  data () {
    return {
      key: 1,
      loading: false,
      confirmLoading: false,
      editconfirmLoading: false,
      dictionaries: Dictionaries,
      shenpi_list: [],
      // 创建窗口控制
      visible: false,
      editvisible: false,
      mdl: {},
      editmdl: {},
      // 高级搜索 展开/关闭
      advanced: false,
      // 查询参数
      queryParam: { },
      // 表头
      columns: [
        {
          title: '序号',
          width: 50,
          scopedSlots: { customRender: 'serial' }
        },
        {
          title: '电表编号',
          dataIndex: 'meter_number',
          ellipsis: true
        },
        {
          title: '企业',
          dataIndex: 'corporation',
          ellipsis: true
        },
        {
          title: '批次',
          dataIndex: 'batch',
          ellipsis: true
        },
        {
          title: '读表数',
          ellipsis: true,
          dataIndex: 'value'
        },
        {
          title: '上期读表数',
          ellipsis: true,
          dataIndex: 'last_value'
        },
        {
          title: '单价',
          ellipsis: true,
          dataIndex: 'price',
          scopedSlots: { customRender: 'money_render_4' }
        },
        {
          title: '总价',
          ellipsis: true,
          dataIndex: 'amount',
          scopedSlots: { customRender: 'money_render' }
        },
        {
          title: '关联状态',
          dataIndex: 'bill_id',
          customRender: (text) => text > 0 ? ' 已关联' : '未关联'
        },
        {
          title: '时间',
          width: 200,
          dataIndex: 'create_time'
        },
        {
          title: '操作',
          dataIndex: 'action',
          width: '150px',
          scopedSlots: { customRender: 'action' }
        }
      ],
      // 加载数据方法 必须为 Promise 对象
      loadData: parameter => {
        return electricity_meter_record_list(Object.assign(parameter, this.queryParam))
          .then(res => {
            console.log(res.data, '我是数据列表@')
            return res.data
          })
      },
      selectedRowKeys: [],
      selectedRows: [],

      // custom table alert & rowSelection
      options: {
        alert: { show: true, clear: () => { this.selectedRowKeys = [] } },
        rowSelection: {
          selectedRowKeys: this.selectedRowKeys,
          onChange: this.onSelectChange
        }
      },
      optionAlertShow: false
    }
  },
  created () {
    this.tableOption()
    console.log(this.$Dictionaries)
  },
  methods: {
    tableOption () {
      if (!this.optionAlertShow) {
        this.options = {
          alert: { show: true, clear: () => { this.selectedRowKeys = [] } },
          rowSelection: {
            selectedRowKeys: this.selectedRowKeys,
            onChange: this.onSelectChange
          }
        }
        this.optionAlertShow = true
      } else {
        this.options = {
          alert: false,
          rowSelection: null
        }
        this.optionAlertShow = false
      }
    },
    handleAdd () {
      this.mdl = null
      this.visible = true
    },
    electricity_bill_c () {
      this.handleEdit()
    },
    handleAddCancel () {
      this.visible = false

      const form = this.$refs.createModal.form
      form.resetFields() // 清理表单数据（可不做）
    },
    handleEditCancel () {
      this.editvisible = false
      const form = this.$refs.editModal.form
      form.resetFields() // 清理表单数据（可不做）
    },
    handleEdit (record) {
      // this.$emit('onEdit', record)
      this.key++
      this.editmdl = record
      this.editvisible = true
    },
    handleDelet (record) {
      electricity_meter_record_delete(record.id).then((res) => {
        console.log(res)
        console.log('删除成功------')
        this.$refs.table.refresh()
      })
    },
    handleOk () {
      const form = this.$refs.createModal.form
      this.confirmLoading = true
      form.validateFields((errors, values) => {
        if (!errors) {
          values.keys = undefined
          // 新增
          var res = values.data
          res = res.replace('，', ',')
          res = res.split('\n')
          res = res.map((item) => {
            return item.split(',')
          })
          values.data = res
          electricity_meter_record_create(values).then(res => {
              this.visible = false
              this.confirmLoading = false
              // 重置表单数据
              form.resetFields()
              // 刷新表格
              this.$refs.table.refresh()
          })
        } else {
          this.confirmLoading = false
        }
      })
    },
    handleEditOk (data) {
      const form = this.$refs.editModal.form
      this.editconfirmLoading = true

      form.validateFields((errors, values) => {
        if (!errors) {
          console.log('values', values, data)
          // 新增
          electricity_bill_create({ ...values, ...data }).then(res => {
            this.editvisible = false
            this.editconfirmLoading = false
            // 重置表单数据
            form.resetFields()
            if (res.code === 1000) {
              // 刷新表格
              this.$refs.table.refresh()
              // 生成电费账单后，生成一个审批请求
              this.createElectricityShenPi(res.data)
            }
          }).finally(() => {
            this.editconfirmLoading = false
          })
        } else {
          this.editconfirmLoading = false
        }
      })
    },
    onSelectChange (selectedRowKeys, selectedRows) {
      this.selectedRowKeys = selectedRowKeys
      this.selectedRows = selectedRows
    },
    toggleAdvanced () {
      this.advanced = !this.advanced
    },

    resetSearchForm () {
      this.queryParam = {
        date: moment(new Date())
      }
    },
    createElectricityShenPi (ids) {
      getForms({ is_stop: 0, template_type: 'electricity_bill' }).then(res => {
        console.log(res)
        this.shenpi_list = res.data.entries
        const len = this.shenpi_list.length
        let is_match = false
        if (len > 0) {
          for (let i = 0; i < len; i++) {
            const item = this.shenpi_list[i]
            const settings = JSON.parse(item.settings)
            if (settings) {
              const notify = settings.notify
              if (notify) {
                const types = notify.types
                if (types) {
                  if (types.type === 'electricity_bill') {
                    is_match = true
                    this.createProcessNotice(item.id, ids, types.type)
                    break
                  }
                }
              }
            }
          }
        }

        if (!is_match) {
          // 没有满足条件的模板，则需要直接更改账单状态
          this.createProcessNotice(-1, ids, 'electricity_bill')
        }
        console.log('=========================================================')
      })
    },
    createProcessNotice (template_id, ids, type) {
      const data = {
        'data_ids': ids,
        'template_id': template_id,
        'template_type': type
      }
      createProcessResult(data).then(res => {
        console.log(res)
        if (res.code === 1000) {
          console.log('创建成功')
        }
      })
    }
  }
}
</script>
