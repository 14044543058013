<template>
  <a-modal
    title="新建"
    :width="1080"
    :visible="visible"
    :confirmLoading="loading"
    @ok="() => { $emit('ok') }"
    @cancel="() => { $emit('cancel') }"
  >
    <a-spin :spinning="loading">
      <a-form :form="form" v-bind="formLayout">
        <a-row :gutter="[16,16]">
          <a-col :span="24">
            <a-form-item
              label="批次"
              v-bind="formLayout"
            >
              <a-input
                v-decorator="['batch', {rules: [{required: true, message: '必填项，请填写信息'}, {max:32, message: '输入超出长度限制'}]}]" />
            </a-form-item>
            <a-form-item
              v-for="(k, index) in form.getFieldValue('keys')"
              :key="k"
              v-bind="formLayout"
              :label="'电表' + (index+1)"
              :required="false"
            >
              <div style="display: flex;justify-content: flex-start;flex-wrap: nowrap;align-items:center;">
                <div style="border-bottom: 1px solid #eee; padding-bottom: 10px;width: 90%">
                  <span>表号：</span>
                  <a-input
                    v-decorator="[
                      `meters[${k}]`,
                      {
                        validateTrigger: ['change', 'blur'],
                        rules: [
                          {
                            required: true,
                            whitespace: true,
                            message: '请输入电表编号',
                          },
                        ],
                      },
                    ]"
                    placeholder="请输入电表编号"
                    style="width: 60%; margin-right: 8px"
                  />
                  <br/>
                  <span>数值：</span>
                  <a-input-number
                    v-decorator="[
                      `value[${k}]`,
                      {
                        validateTrigger: ['change', 'blur'],
                        rules: [
                          {
                            required: true,
                            whitespace: true,
                            message: '请输入电表数值',
                          },
                        ],
                      },
                    ]"
                    placeholder="请输入电表数值"
                    style="width: 60%; margin-right: 8px"
                  />
                </div>
                <a-icon
                  v-if="form.getFieldValue('keys').length > 1"
                  class="dynamic-delete-button"
                  type="minus-circle-o"
                  :disabled="form.getFieldValue('keys').length === 1"
                  @click="() => remove(k)"
                />
              </div>
            </a-form-item>
            <a-form-item label="读数">
              <a-textarea v-decorator="['data', {rules: [{required: true, message: '必填项，请填写信息'}]}]" />
              <p>输入规则：表号,表读数 (注意：逗号使用英文,) 多组数据，请换行，一行填写一组数据。</p>
            </a-form-item>
          </a-col>
        </a-row>
      </a-form>
    </a-spin>
  </a-modal>
</template>

<script>
let id = 0
export default {
  components: {},
  props: {
    visible: {
      type: Boolean,
      required: true
    },
    loading: {
      type: Boolean,
      default: () => false
    },
    model: {
      type: Object,
      default: () => null
    }
  },
  data () {
    return {
      color: '#3f3f3f',
      form: this.$form.createForm(this),
      groupData: [],
      dataSource: [],
      formLayout: {
        labelCol: {
          xs: { span: 24 },
          sm: { span: 7 }
        },
        wrapperCol: {
          xs: { span: 24 },
          sm: { span: 13 }
        }
      },
      formItemLayoutWithOutLabel: {
        wrapperCol: {
          xs: { span: 16, offset: 4 },
          sm: { span: 16, offset: 4 }
        }
      }
    }
  },
  created () {
    this.form.getFieldDecorator('keys', { initialValue: [], preserve: true })
  },
  methods: {
    add () {
      const keys = this.form.getFieldValue('keys')
      const nextKeys = keys.concat(id++)
      this.form.setFieldsValue({
        keys: nextKeys
      })
    },
    remove (k) {
      const keys = this.form.getFieldValue('keys')
      if (keys.length === 1) {
        return
      }
      this.form.setFieldsValue({
        keys: keys.filter(key => key !== k)
      })
    }
  }
}
</script>
