var render = function render(){var _vm=this,_c=_vm._self._c;return _c('a-modal',{attrs:{"title":"新建","width":1080,"visible":_vm.visible,"confirmLoading":_vm.loading},on:{"ok":() => { _vm.$emit('ok') },"cancel":() => { _vm.$emit('cancel') }}},[_c('a-spin',{attrs:{"spinning":_vm.loading}},[_c('a-form',_vm._b({attrs:{"form":_vm.form}},'a-form',_vm.formLayout,false),[_c('a-row',{attrs:{"gutter":[16,16]}},[_c('a-col',{attrs:{"span":24}},[_c('a-form-item',_vm._b({attrs:{"label":"批次"}},'a-form-item',_vm.formLayout,false),[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:(['batch', {rules: [{required: true, message: '必填项，请填写信息'}, {max:32, message: '输入超出长度限制'}]}]),expression:"['batch', {rules: [{required: true, message: '必填项，请填写信息'}, {max:32, message: '输入超出长度限制'}]}]"}]})],1),_vm._l((_vm.form.getFieldValue('keys')),function(k,index){return _c('a-form-item',_vm._b({key:k,attrs:{"label":'电表' + (index+1),"required":false}},'a-form-item',_vm.formLayout,false),[_c('div',{staticStyle:{"display":"flex","justify-content":"flex-start","flex-wrap":"nowrap","align-items":"center"}},[_c('div',{staticStyle:{"border-bottom":"1px solid #eee","padding-bottom":"10px","width":"90%"}},[_c('span',[_vm._v("表号：")]),_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([
                    `meters[${k}]`,
                    {
                      validateTrigger: ['change', 'blur'],
                      rules: [
                        {
                          required: true,
                          whitespace: true,
                          message: '请输入电表编号',
                        },
                      ],
                    },
                  ]),expression:"[\n                    `meters[${k}]`,\n                    {\n                      validateTrigger: ['change', 'blur'],\n                      rules: [\n                        {\n                          required: true,\n                          whitespace: true,\n                          message: '请输入电表编号',\n                        },\n                      ],\n                    },\n                  ]"}],staticStyle:{"width":"60%","margin-right":"8px"},attrs:{"placeholder":"请输入电表编号"}}),_c('br'),_c('span',[_vm._v("数值：")]),_c('a-input-number',{directives:[{name:"decorator",rawName:"v-decorator",value:([
                    `value[${k}]`,
                    {
                      validateTrigger: ['change', 'blur'],
                      rules: [
                        {
                          required: true,
                          whitespace: true,
                          message: '请输入电表数值',
                        },
                      ],
                    },
                  ]),expression:"[\n                    `value[${k}]`,\n                    {\n                      validateTrigger: ['change', 'blur'],\n                      rules: [\n                        {\n                          required: true,\n                          whitespace: true,\n                          message: '请输入电表数值',\n                        },\n                      ],\n                    },\n                  ]"}],staticStyle:{"width":"60%","margin-right":"8px"},attrs:{"placeholder":"请输入电表数值"}})],1),(_vm.form.getFieldValue('keys').length > 1)?_c('a-icon',{staticClass:"dynamic-delete-button",attrs:{"type":"minus-circle-o","disabled":_vm.form.getFieldValue('keys').length === 1},on:{"click":() => _vm.remove(k)}}):_vm._e()],1)])}),_c('a-form-item',{attrs:{"label":"读数"}},[_c('a-textarea',{directives:[{name:"decorator",rawName:"v-decorator",value:(['data', {rules: [{required: true, message: '必填项，请填写信息'}]}]),expression:"['data', {rules: [{required: true, message: '必填项，请填写信息'}]}]"}]}),_c('p',[_vm._v("输入规则：表号,表读数 (注意：逗号使用英文,) 多组数据，请换行，一行填写一组数据。")])],1)],2)],1)],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }